import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FaqCategory({ faqId, header, text }) {
  return (
    <Accordion className="faq-category" key={"Accordion"+faqId}>
      <AccordionSummary key={"AccordionSummary"+faqId}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={"panel-content" + faqId}
        id={"panel-header" + faqId}
      >
        <Typography className="h4" key={"h4"+faqId}>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails key={"AccordionDetails"+faqId}>{text}</AccordionDetails>
    </Accordion>
  );
}
